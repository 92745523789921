import VideoCarousel from "../../../components/ui/VideoCarousel";
import live1 from '../../../assets/images/svg/RadarPage/live1.svg';
import LiveStream from '../../../hooks/useGetWelcomeVideos';

const LiveStreamComponent = ({ page }) => {

    const data = LiveStream({ 'vidType': 'Original', 'page': page, 'limit': 10 });

    if (!data || data.length === 0) {
        return null; 
    }

    return (
        data?.length &&
        <VideoCarousel data={{ list: data }} img={live1} title={'live streaming'} />
    )
}

export default LiveStreamComponent


