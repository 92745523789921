import { useSelector, useDispatch } from 'react-redux';
import { setUsername } from './actions';
import { useCallback } from 'react';

// dispatchers
export function useSetUsername(jwt) {
    const dispatch = useDispatch();
    return useCallback((username) => dispatch(setUsername({ username })), [dispatch]);
}

// selectors
export function useGetUsername() {
    const username1 = useSelector((state) => state._static.username)
    const username2 = useSelector((state) => state._static.userProfile.username)
    const name = useSelector((state) => state._static.name)
    const name2 = useSelector((state) => state._static.userProfile.name)
    return username1 || username2 || name || name2;
    // return useSelector((state) => state._static.username || state._static.name)
}

export function useGetname() {
    return useSelector((state) => state._static.name)
}

export function useGetUserId() {
    const id = useSelector((state) => state._static.id)
    const id2 = useSelector((state) => state._static.userId)
    // return useSelector((state) => state._static.id)
    // console.log('userId id1 & id2', id, id2);
    return id || id2;
}


export function useGetUserProfileId() {
    return useSelector((state) => state._static.userProfile._id)
}

export function useGetUserProfilePicture() {
    return useSelector((state) => state._static.userProfile.profilePicture)
}

export function useGetUserProfileExists() {
    return useSelector((state) => state._static.userProfileExists)
}

export function useGetUserSearchHistory() {
    return useSelector((state) => state._static.userSearchHistory)
}

export function useGetSearchSuggestionList() {
    return useSelector((state) => state._static.searchSuggestionList)
}

export function useGetLoggedInUserProfileData() {
    return useSelector((state) => state._static.userProfile)
}

export function useGetUserBio() {
    return useSelector((state) => state._static.userProfile.bio)
}