import Button from '../../../components/ui/Buttons';
import { useNavigate } from 'react-router-dom';
import classes from './WelcomeBannerAs.module.css'
import applestore from '../../../assets/images/svg/LandingPage/svg/WelcomeBanner/appleStore.svg'
import dots from '../../../assets/images/svg/LandingPage/svg/WelcomeBanner/dots.svg'
import music from '../../../assets/images/svg/LandingPage/svg/WelcomeBanner/music.svg'
import altLogoWhite from '../../../assets/images/svg/altRadar-logo-white.svg'
import playstore from '../../../assets/images/svg/LandingPage/svg/WelcomeBanner/playStore.svg'

const WelcomeBannerComponent = () => {
    const navigate = useNavigate();

    const signup = () => {
        navigate('/signInAs', { state: { key: 'signup' } });
    }

    const signin = () => {
        navigate('/signInAs', { state: { key: 'signin' } });
    }

    return (
        <>
            <div className={classes.banneras} >
                <div
                >
                    <div className={classes.LoginSingupBtn}>
                        <Button label="sign in" handleClick={() => { signin() }} className={"signin active"} />
                        <Button label="sign up" handleClick={() => { signup() }} className={"signup"} />
                    </div>

                    <div className={classes.dotsDiv}>
                        <img src={dots} className={classes.dots} alt=""
                        />
                        <div className={classes.radialYellow}></div>
                    </div>
                    <img src={altLogoWhite} className={classes.altlogo} alt=""
                    />
                    <div className={classes.musicDiv}>
                        <h3 className={classes.heading} style={{ marginBottom: '2rem' }}>Where music videos get played <br />and artists get paid !</h3>
                        <div className={classes.BtnGray}>Find and listen to your favorite music</div>
                        <div className={classes.or}>or</div>
                        <div className={classes.uploadBtnDiv}>
                            <Button label="Upload your own" className={"signin active"} />
                        </div>
                    </div>

                    <img src={music} className={classes.music} alt="" />
                    <div className={classes.radialRed}></div>


                    <div className={classes.dotsDiv2}>
                        <img className={classes.dots2} src={dots} alt="" />
                        <div className={classes.radialBlue}></div>
                        <div className={classes.mobileStore}>
                            <img src={playstore} alt="playstore" className={`${classes.playstore} ${classes.storeImage}`} />
                            <img src={applestore} alt="applestore" className={classes.storeImage} />
                        </div>
                    
                    </div>

                </div>

            </div >

        </>
    )
}


export default WelcomeBannerComponent