import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Buttons';
import AuthCard from '../../components/auth/AuthCard';
import classes from './signInAs.module.css'
import altLogoWhite from '../../assets/images/svg/altRadar-logo-white.svg'
import ForgotPassword from './ForgotPassword';

const AuthComponent = () => {
    const Navigate = useNavigate();
    const { state } = useLocation();
    const [key, setKey] = useState([]);
    // console.log(key);
    const [type, setType] = useState('artist');
    const [action, setAction] = useState('signin');
    const [forgotPassword, setForgotPassword] = useState(false);
    useEffect(() => {
        if (state.key === 'artist') {
            setKey(['artist', 'band'])
            setType('artist')
        } else if (state.key === 'listener') {
            setType('listener')
        }
        if (state.action) {
            setAction(state.action);
        }
    }, [state.action, state.key])


    const updateForgot = () => {
        setForgotPassword(true);
    }

    return (
        <>
            <div className='container-fluid'>
                <div className={`${classes.signinas} row`}>
                    <div className='col'>
                        <div className='row'>
                            <div className='col'>
                                <img src={altLogoWhite} alt="altRadar logo" className={classes.logo} onClick={() => Navigate(-1)} />
                            </div>
                        </div>
                        <div className='row' style={{
                            height: "74.2%",
                            top: "23.2vh",
                            display: "flex",
                            justifyContent: "space-around",
                        }}>

                            {!forgotPassword && (
                                <>
                                    <div className='col-lg-5' style={{ padding: "6rem 0" }}>
                                        <div className='py-3' style={{ padding: "0 0 2rem" }}>
                                            <h1 className={classes.heading} style={{ padding: "0 0 2rem" }}>
                                                Sign {action === 'signin' ? 'in ' : 'up '}
                                                as {type === 'artist' ? 'an ' : 'a '}
                                            </h1>

                                            {state.key === 'artist'
                                                ?
                                                (
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <Button label='artist' handleClick={() => setType('artist')} className={`signinas ${type === 'artist' ? 'active' : 'inActive'}`} />
                                                        </div>
                                                        <div className='col-6'>
                                                            <Button label='band' handleClick={() => setType('band')} className={`signinas ${type === 'band' ? 'active' : 'inActive'}`} />
                                                        </div>
                                                    </div>
                                                ) :
                                                (
                                                    <>
                                                        <Button label='listener' handleClick={() => setType('listener')} className={"signinas active"} />
                                                    </>)}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <AuthCard type={type} action={state.action} setForgotPassword={updateForgot} />
                                    </div>
                                </>
                            )}

                            {forgotPassword && (
                                <ForgotPassword />
                            )}



                        </div>
                    </div>



                </div>
            </div>

        </>
    )
}

export default AuthComponent