import { createReducer, current } from '@reduxjs/toolkit';
import {
    onBoardingPageNum,
    setRole,
    clearApplication,
    toggleSidebar,
    setVideoPageComments,
    appendVideoPageComments,
    appendVideoPageSubComment,
    deleteVideoPageComments,
    deleteVideoPageSubComments,
    // pushVideoPageComments,
    setVideoShareState,
    setUploadVideoSnapshots,
    setSnapshotId,
    setClearApplication,
} from './actions';

const initialState = {
    onBoardingPageNum: 0,
    role: '',
    toggleSidebar: false,
    videoPageComments: [],
    videoShareState: false,
    uploadVideoSnapshots: []
}

export default createReducer(initialState, (builder) => {
    builder
        .addCase(onBoardingPageNum, (state, action) => {
            state.onBoardingPageNum = action.payload;
        })
        .addCase(setRole, (state, action) => {
            state.role = action.payload;
        })
        .addCase(clearApplication, (state) => {
            state.onBoardingPageNum = 0;
            state.role = '';
        })
        .addCase(toggleSidebar, (state) => {
            state.sidebar = !state.sidebar
        })
        .addCase(setVideoPageComments, (state, action) => {
            if (action.payload.type === 'set') {
                state.videoPageComments = action.payload.data;
            } else {
                state.videoPageComments = [...current(state.videoPageComments), ...action.payload.data];
            }
        })
        .addCase(appendVideoPageComments, (state, action) => {
            state.videoPageComments = [action.payload, ...state.videoPageComments];
        })
        .addCase(appendVideoPageSubComment, (state, action) => {
            // Update the specific subComments array
            state.videoPageComments = state.videoPageComments.map((comment, index) => {
                if (index === action.payload.index) {
                    return {
                        ...comment,
                        subComments: [...comment.subComments, action.payload.data]
                    };
                }
                return comment;
            });
        })
        .addCase(setVideoShareState, (state, action) => {
            state.videoShareState = action.payload;
        })
        .addCase(deleteVideoPageComments, (state, action) => {
            state.videoPageComments = state.videoPageComments.filter(comment => comment._id !== action.payload.commentId);
        })
        .addCase(deleteVideoPageSubComments, (state, action) => {
            const { id, subId } = action.payload;

            state.videoPageComments = state.videoPageComments.map(comment => {
                if (comment._id === id) {
                    if (subId) {
                        return {
                            ...comment,
                            subComments: comment.subComments.filter(subComment => subComment._id !== subId)
                        };
                    }
                }
                return comment;
            });
        })
        .addCase(setUploadVideoSnapshots, (state, action) => {
            state.uploadVideoSnapshots = action.payload;
        })
        .addCase(setSnapshotId, (state, action) => {
            state.snapshotId = action.payload;
        })
        .addCase(setClearApplication, (state) => {
            state.onBoardingPageNum = 0;
            state.role = '';
            state.videoPageComments = [];
            state.videoShareState = false;
            state.uploadVideoSnapshots = [];
            localStorage.clear();
        })
});
