import { createAction } from '@reduxjs/toolkit';

export const onBoardingPageNum = createAction("application/onBoardingPageNum")
export const setRole = createAction("application/role")
export const clearApplication = createAction("application/clearApplication")
export const toggleSidebar = createAction("application/toggleSidebar")
export const setVideoPageComments = createAction("application/setVideoPageComments")
export const pushVideoPageComments = createAction("application/setVideoPageComments")
export const appendVideoPageComments = createAction("application/appendVideoPageComments")
export const deleteVideoPageComments = createAction("application/deleteVideoPageComments")
export const deleteVideoPageSubComments = createAction("application/deleteVideoPageSubComments")
export const appendVideoPageSubComment = createAction("application/appendVideoPageSubComment")
export const setVideoShareState = createAction("application/setVideoShareState")
export const setUploadVideoSnapshots = createAction("application/setUploadVideoSnapshots") // get upload video snapshots
export const setSnapshotId = createAction("application/setSnapshotId")

export const setClearApplication = createAction("application/setClearApplication")