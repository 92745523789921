// import Button from 'react-bootstrap/Button';
// import Button from '../Buttons';
import Modal from 'react-bootstrap/Modal';
// import classes from './modal.module.css';
import './modal.css';

function BootstrapModal({ show, showHeader, handleClose, handleSubmit, title, body, footer, className, size }) {
    return (
        <>
            <Modal
                size={size}
                className={className}
                show={show} onHide={handleClose} backdrop="static" keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                data-bs-theme="dark"
            >
                {showHeader &&
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                }

                <Modal.Body>{body}</Modal.Body>
                {footer &&
                    <Modal.Footer>
                        {footer}
                    </Modal.Footer>}

            </Modal>
        </>
    );
}

export default BootstrapModal;