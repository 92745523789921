import moment from 'moment';

const facebookProfileUrlRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.]{1,}$/;
const instagramProfileUrlRegex = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;
const youtubeVedioUrlRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
const twitterProfileUrlRegex = /(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/;
const spotifyProfileUrlRegex = /^(?:spotify:|https:\/\/[a-z]+\.spotify\.com\/(track\/|user\/(.*)\/playlist\/))(.*)$/
const tiktokProfileUrlRegex = /(@[a-zA-z0-9]*|.*)(\/.*\/|trending.?shareId=)([\d]*)/

export const checkEmail = (email) => {
    const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const result = pattern.test(email) || email.length === 0;
    return result;
}

export const comparePassword = (password, confirmPassword) => {
    return password === confirmPassword;
}

export const handleCheckName = (name) => {
    return name.length < 3;
}

export const handleUsername = (username) => {

    if (username.length < 16) {
        const data = username.substring(0, 15);
        const trimmedUsername = data.replace(/\s/g, ''); // Remove spaces
        return trimmedUsername;
    }
    // else {
    //     const trimmedUsername = username.replace(/\s/g, ''); // Remove spaces
    //     return trimmedUsername;
    // }
}

export const handleName = (name) => {
    if (name.length === 30) {
        return name.substring(0, 29)
    }
    else {
        return name
    }
}

export const handlePassword = (password) => {
    if (password.length === 15) {
        return password.substring(0, 14)
    }
    else {
        return password
    }
}

export const signUpValidation = (name, email, password, confirmPassword) => {
    const errors = []
    if (handleCheckName(name)) {
        errors.push({ name: 'Name should be between 3 to 30 characters' });
    }
    if (!checkEmail(email)) {
        errors.push({ email: 'Provide a valid email' });
    }
    if (handlePassword(password).length < 6) {
        console.log('In LL ');
        errors.push({ password: 'Provide a valid password' });
    }
    if (!comparePassword(password, confirmPassword)) {
        errors.push({ confirmPassword: 'Incorrect password' });
    }
    return errors
}

export const verifyEnteredLink = (newLinks) => {
    console.log('newLinks :: ', newLinks);
    let err = [];
    let isValid = true;
    if (newLinks.facebook && !newLinks.facebook.match(facebookProfileUrlRegex)) {
        err.facebook = 'Invalid url'
        isValid = false;
    } else {
        err.facebook = ''
    }
    if (newLinks.instagram && !newLinks.instagram.match(instagramProfileUrlRegex)) {
        err.instagram = 'Invalid url'
        isValid = false;
    } else {
        err.instagram = ''
    }
    if (newLinks.youtube && !newLinks.youtube.match(youtubeVedioUrlRegex)) {
        err.youtube = 'Invalid url'
        isValid = false;
    } else {
        err.youtube = ''
    }
    if (newLinks.twitter && !newLinks.twitter.match(twitterProfileUrlRegex)) {
        err.twitter = 'Invalid url'
        isValid = false;
    } else {
        err.twitter = ''
    }
    if (newLinks.tiktok && !newLinks.tiktok.match(tiktokProfileUrlRegex)) {
        err.tiktok = 'Invalid url'
        isValid = false;
    } else {
        err.tiktok = ''
    }
    if (newLinks.spotify && !newLinks.spotify.match(spotifyProfileUrlRegex)) {
        err.spotify = 'Invalid url'
        isValid = false;
    } else {
        err.spotify = ''
    }

    return err
}

// export const momentFromNow = (date) => {
//     if (typeof (date) === "string") {
//         return moment(new Date(parseInt(date))).fromNow();
//     }
//     else if (typeof (date) === "number") {
//         return moment(new Date(date)).fromNow();
//     }
// }

export const momentFromNow = (date) => {
    if (typeof date === "string") {
        // Check if the date is in ISO format, then parse it correctly
        // Directly pass the string to moment if it's in ISO 8601 format
        // const format1 = moment(new Date(parseInt(date))).fromNow();
        const format2 = moment(date).fromNow();
        // console.log('format1', format1, 'format2', format2);
        return format2;
    } else if (typeof date === "number") {
        // Handle number types as milliseconds
        return moment(new Date(date)).fromNow();
    }
    // Handle other cases or invalid date formats
    return "Invalid date";
};

export const blobToBinary = (dataUrl) => {
    let BASE64_MARKER = ';base64,';
    if (dataUrl.indexOf(BASE64_MARKER) === -1) {
        // console.log('356')
        let parts = dataUrl.split(',');
        let contentType = parts[0].split(':')[1];
        let raw = decodeURIComponent(parts[1]);
        return new Blob([raw], { type: contentType });
    }

    let parts = dataUrl.split(BASE64_MARKER);
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    let blobimage = new Blob([uInt8Array], { type: contentType })

    const fileName = 'image.jpg';
    const binaryFile = new File([blobimage], fileName, { type: blobimage.type });
    return binaryFile;
}

export const nFormatter = (num, digits) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast(item => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
}

export const userLoginSocial = (payload) => {
    const accessToken = new URLSearchParams(payload).get('access_token');
    const refreshToken = new URLSearchParams(payload).get('refresh_token');
    const userProfileExists = new URLSearchParams(payload).get('userProfileExists');
    const userId = new URLSearchParams(payload).get('userId');
    const tokenExpiresAt = new URLSearchParams(payload).get('tokenExpiresAt');
    const role = new URLSearchParams(payload).get('role');

    return {
        accessToken,
        refreshToken,
        userProfileExists,
        userId,
        tokenExpiresAt,
        role
    }
}