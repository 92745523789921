import React from 'react';
import classes from './triviaAs.module.css'
import VideoCarousel from '../../../components/ui/VideoCarousel';
import live1 from '../../../assets/images/svg/LandingPage/svg/dice.svg';
import progress from '../../../assets/images/svg/LandingPage/svg/sandClock.svg';
// import live3 from '../../../assets/images/svg/LandingPage/svg/cards.svg';

const TriviaComponent = () => {
    const dummySet = Array.from({ length: 10 }, (_, index) => (
        {
            videoInfo: {
                thumbnail: live1
            }
        }
    ))
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 col-sm-6 col-xs-12">
                    <div className={classes.bgTrivia}>
                        <span>
                            <span>Welcome to</span>
                            <h1>The Ultimate altRadar Trivia</h1>
                        </span>
                    </div>
                </div>
                <div className={`${classes.rightSection} col-md-4 col-sm-6 col-xs-12 `}>
                    <div className={classes.masknew}>
                        <p style={{ marginBottom: "unset" }}>Enter</p>
                        <h3>Trivia Night</h3>
                    </div>
                    <div className={classes.masknews}>
                        <div className='mt-2'>
                            <img src={progress} alt="progress" className={classes.progress} />
                        </div>
                        <div className='text-center'>
                            <small>Starts in</small>
                            <div className={classes.numbers}>10</div>
                            <small>HOURS</small>
                        </div>

                        <div className='text-center'>
                            <span>&nbsp;</span>
                            <div className={classes.numbers}>20</div>
                            <small>MINUTES</small>
                        </div>
                        <div className='text-center'>
                            <span>&nbsp;</span>
                            <div className={classes.numbers}>59</div>
                            <small>SECONDS</small>
                        </div>
                    </div>
                </div>
            </div>

            <VideoCarousel data={{ list: dummySet }} img={live1} type={'trivia'} />

        </div>
    );
};

export default TriviaComponent;
