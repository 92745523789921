import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useEffect, Suspense, lazy } from 'react';
import TopNavigation from '../components/Navigation/TopNavigation';
import SideNavigation from '../components/Navigation/SideNavigation';
import Footer from '../pages/Footer';
import AdvertisementPage from '../pages/logged-in/AdvertisementPage';
import AdManagerPage from '../pages/logged-in/AdManagerPage';
import AdDemographicsPage from '../pages/logged-in/AdDemographicsPage';
import { useGetUserProfileExists } from '../store/redux/user/hooks';
import MusicLoader from '../components/ui/Loader/music';
// ------------------------------- POC Start -------------------------------
import Temporary from '../pages/Temporary';
import YoutubeDataSearchPoc from '../pages/Temporary/youtubeDataSearchPoc';
// ------------------------------- POC End -------------------------------

const ProfileOnboard = lazy(() => import('../pages/logged-in/ProfileOnboardPage'));
const RadarPage = lazy(() => import('../pages/logged-in/RadarPage'));
const VideoPage = lazy(() => import('../pages/VideoPage'));
const ChartPage = lazy(() => import('../pages/logged-in/ChartPage'));
const TimelinePage = lazy(() => import('../pages/logged-in/TimelinePage'));
const SeeAllPhotosPage = lazy(() => import('../pages/logged-in/TimelinePage/seeAllPhotos'));
const SeeAllVideosPage = lazy(() => import('../pages/logged-in/TimelinePage/seeAllVideos'));
const SettingsPage = lazy(() => import('../pages/logged-in/SettingsPage'));
// const EditProfilePage = lazy(() => import('../pages/logged-in/EditProfilePage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));
const AccountSettingsPage = lazy(() => import('../pages/logged-in/AccountSettingsPage'));
const EarningsPage = lazy(() => import('../pages/logged-in/EarningsPage'));
const BankDetailsPage = lazy(() => import('../pages/logged-in/Profile/BankDetails'));
const FAQDetailsPage = lazy(() => import('../pages/logged-in/Profile/FAQDetails'));
const SubscriptionDetailsPage = lazy(() => import('../pages/logged-in/Profile/SubscriptionDetails'));
const SearchPage = lazy(() => import('../pages/logged-in/SearchPage'));
const MyVideosPage = lazy(() => import('../pages/logged-in/MyVideos'));
const FanCodePage = lazy(() => import('../pages/logged-in/FanCodePage'));
const UploadVideoPage = lazy(() => import('../pages/logged-in/UploadVideoPage'));
const EditVideoPage = lazy(() => import('../pages/logged-in/EditVideoPage'));
const DemographicsPage = lazy(() => import('../pages/logged-in/Demographics'));
const WatchLaterPage = lazy(() => import('../pages/logged-in/WatchLaterPage'));
const LeaderboardPage = lazy(() => import('../pages/logged-in/Leaderboard'));
const DemographicsVideoPage = lazy(() => import('../pages/logged-in/DemographicsVideo'));
const NotificationsPage = lazy(() => import('../pages/logged-in/NotificationsPage'));
const MessagesPage = lazy(() => import('../pages/logged-in/MessagesPage'));
const LiveStreamingPage = lazy(() => import('../pages/logged-in/LiveStreamingPage'));
const PlaylistPage = lazy(() => import('../pages/logged-in/PlaylistPage'));
const SeeMorePage = lazy(() => import('../pages/logged-in/SeeMorePage'));
const AllFollowersPage = lazy(() => import('../pages/logged-in/AllFollowersPage'));

const AppRoutes = () => {
    const location = useLocation();
    const path = location.pathname;
    const userProfileExists = useGetUserProfileExists();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    if (!userProfileExists && path !== '/main-profile') {
        return <Navigate to="/main-profile" />;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            {path !== '/main-profile' && (
                <TopNavigation />
            )}
            <div style={{ display: 'flex', flex: 1, paddingTop: '0' }}>
                {path !== '/main-profile' && <SideNavigation />}
                <div className={`container-fluid ${path !== '/main-profile' ? 'my-5 py-5' : ''}`}
                    style={{
                        padding: (path !== '/main-profile') ? '1rem' : '', overflowX: 'hidden', flex: 1,
                        transition: 'all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s',
                    }}
                >
                    <Suspense fallback={<MusicLoader />}>
                        <Routes>
                            <Route path='/main-profile' element={<ProfileOnboard />} />
                            <Route path="/radar" element={<RadarPage />} />
                            <Route path="/radar/:id" element={<SeeMorePage />} />
                            <Route path="/video-player" element={<VideoPage />} />
                            <Route path="/charts" element={<ChartPage />} />
                            <Route path="/profile" element={<TimelinePage />} />
                            <Route path="/profile/:id" element={<TimelinePage />} />
                            <Route path="/notifications" element={<NotificationsPage />} />
                            <Route path="/messages" element={<MessagesPage />} />

                            <Route path="/all-photos" element={<SeeAllPhotosPage />} />
                            <Route path="/all-videos" element={<SeeAllVideosPage />} />
                            <Route path="/all-follower" element={<AllFollowersPage />} />
                            <Route path="/all-following" element={<AllFollowersPage />} />

                            <Route path="/settings" element={<SettingsPage />} />
                            <Route path="/settings/account" element={<AccountSettingsPage />} />
                            {/* <Route path="/profile/edit" element={<EditProfilePage />} /> */}
                            <Route path="/earnings" element={<EarningsPage />} />

                            <Route path="/my-playlist" element={<PlaylistPage />} />

                            {/* User */}
                            <Route path="/profile/bank-details" element={<BankDetailsPage />} />
                            <Route path="/profile/FAQ" element={<FAQDetailsPage />} />
                            <Route path="/profile/Subscription" element={<SubscriptionDetailsPage />} />
                            <Route path="/profile/demographics" element={<DemographicsPage />} />
                            <Route path="/profile/demographicsvideo" element={<DemographicsVideoPage />} />
                            <Route path="/search" element={<SearchPage />} />
                            <Route path="/my-videos" element={<MyVideosPage />} />
                            <Route path="/fan-code" element={<FanCodePage />} />

                            {/* Artist/Band Specific */}
                            <Route path="/upload" element={<UploadVideoPage />} />
                            <Route path="/edit-video/:id" element={<EditVideoPage />} />
                            <Route path="/advertisement" element={<AdvertisementPage />} />
                            <Route path="/advertisement/upload/new" element={<AdManagerPage />} />
                            <Route path="/advertisement/view/:id" element={<AdDemographicsPage />} />

                            <Route path="/watch-later" element={< WatchLaterPage />} />
                            <Route path="/leaderboard" element={<LeaderboardPage />} />

                            <Route path="/video/live-streaming" element={<LiveStreamingPage />} />

                            <Route path="/gcp-video" element={<Temporary />} />
                            <Route path="/gcp-youtube-poc" element={<YoutubeDataSearchPoc />} />

                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </Suspense>

                    {path !== '/main-profile' && <Footer />}
                </div>
            </div>
        </div>
    );
}

export default AppRoutes;
