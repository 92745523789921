import { useDispatch, useSelector } from 'react-redux';

// Dispatchers
export const useDispatchOnBoardingPageNum = () => {
    const dispatch = useDispatch();
    return (onBoardingPageNum) => dispatch(onBoardingPageNum(onBoardingPageNum))
}

// Selectors
export const useGetOnBoardingPageNum = () => {
    return useSelector((state) => state._app.onBoardingPageNum)
}

export const useGetRole = () => {
    return useSelector((state) => state._app.role)
}

export const useGetToggleSidebar = () => {
    return useSelector((state) => state._app.sidebar)
}

export const useGetVideoPageComments = () => {
    return useSelector((state) => state._app.videoPageComments)
}

export const useGetVideoShareState = () => {
    return useSelector((state) => state._app.videoShareState)
}

// get upload video snapshots
export const useGetUploadVideoSnapshots = () => {
    return useSelector((state) => state._app.uploadVideoSnapshots)
}
export const useGetSnapshotId = () => {
    return useSelector((state) => state._app.snapshotId)
}