import axios from 'axios';
import { setClearToken } from '../../store/redux/token/actions';
import { store } from '../../store/redux';
import { toast } from "react-toastify";

const formApiCaller = async (endpoint, method, body, cancelToken) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://18.204.254.128:8070';
    const jwt = store.getState()._session.access_token;

    const headers = {};
    if (jwt) {
        headers['Authorization'] = `Bearer ${jwt}`;
        headers['content-type'] = 'multipart/form-data';
    }

    const config = {
        method: method,
        url: `${baseUrl}${endpoint}`,
        headers: {
            ...headers,
            ...(body instanceof FormData ? {} : { 'Content-Type': 'application/json' }) // Only set Content-Type if not FormData
        },
        data: body instanceof FormData ? body : JSON.stringify(body), // If body is FormData, leave it as is, else stringify it
        cancelToken: cancelToken // Pass the cancel token to the request config
    };

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            if (error.response && error.response.status === 400) {
                // toast(error.response.data.message, { theme: "dark" });
                // console.log(error.response.data);
                return error.response;
            } else if (error.response && error.response.status === 401) {
                store.dispatch(setClearToken());
                window.location.reload();
            } else if (error.response && error.response.status === 404) {
                toast(error.response.data.message, { theme: "dark" });
            } else {
                if (window.navigator.onLine) {
                    toast('Server Down!', { theme: "dark" });
                } else {
                    toast('No internet connection!', { theme: "dark" });
                }
            }
            throw error; // rethrow the error to maintain error propagation
        }
    }
};

export const createCancelToken = () => {
    const source = axios.CancelToken.source();
    return source;
};

export default formApiCaller;
