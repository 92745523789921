import { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './video-js.css';

const VideoJs = (props) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const { options, onReady, showShareButton, showAddWatchLaterButton, showNextButton } = props;

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = document.createElement('video-js');
            videoElement.classList.add('vjs-big-play-centered');
            videoElement.classList.add('vjs-altRadar');
            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, {
                ...options,
                controlBar: {
                    children: [
                        'playToggle', // Play/Pause button
                        (showNextButton === true) && 'nextButton', // Custom button
                        'volumePanel',
                        'currentTimeDisplay',
                        'timeDivider',
                        'durationDisplay',
                        'progressControl',
                        'pictureInPictureToggle',
                        'fullscreenToggle',
                    ]
                }
            }, () => {
                // videojs.log('player is ready');
                onReady && onReady(player);

                // Create a custom button
                const Button = videojs.getComponent('Button');

                if (showNextButton) {
                    const nextButton = new Button(player, {
                        text: 'next button'
                    });

                    nextButton.addClass('vjs-next-button');
                    nextButton.controlText('Custom Button');
                    nextButton.on('click', () => {
                        alert('Next button clicked!');
                    });

                    // Add the custom button to the control bar at the second index
                    const controlBar = player.getChild('controlBar');
                    controlBar.addChild(nextButton, {}, 1); // Position at the second index
                }

                // Share button
                if (showShareButton) {
                    // Share button
                    const shareButton = new Button(player, { /* options */ });

                    shareButton.addClass('vjs-share-button');
                    shareButton.on('click', () => {
                        if (player.isFullscreen()) {
                            player.exitFullscreen();
                        }
                        props.shareVideo();
                    });

                    // Add the custom button to the player element
                    player.el().appendChild(shareButton.el());
                    shareButton.addClass('vjs-share-button-top-right');
                }

                // Watch Later button
                if (showAddWatchLaterButton) {
                    const watchLaterButton = new Button(player, { /* options */ });

                    watchLaterButton.addClass('vjs-watchlater-button');
                    watchLaterButton.on('click', () => {
                        if (player.isFullscreen()) {
                            player.exitFullscreen();
                        }
                        // alert('Watch Later button clicked!');
                        props.addWatchLater();
                    });

                    // Add the custom button to the player element
                    player.el().appendChild(watchLaterButton.el());
                    watchLaterButton.addClass('vjs-watchlater-button-top-right');
                }

            });

        } else {
            const player = playerRef.current;
            player.addClass('vjs-altRadar');
            player.autoplay(options.autoplay);
            player.src(options.sources);
        }
    }, [options, onReady]);

    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, []);

    return (
        <div data-vjs-player>
            <div ref={videoRef}></div>
        </div>
    );
}

export default VideoJs;
